import {useEffect, useState} from 'react';
import {PiDotsThreeOutlineVerticalFill} from "react-icons/pi";
import {LiaPencilAltSolid} from "react-icons/lia";
import './GalleryList.scss';
import axios from "axios";
import {BASE_URL} from "../../services/baseURL";
import {deleteGallery} from "../../services/galleryService";

const GalleryList = ({onCreateClick, onEditClick}) => {
    const [galleris, setGalleris] = useState([])
    const [statusDelete, setStatusDelete] = useState('')


    useEffect(() => {

        axios.get(`${BASE_URL}/api/galleries`).then(data => {
            setGalleris(data.data.data)
        })
            .catch(error => {
            });

    }, [statusDelete]);

    const deleteGalleryById = (id) => {
        deleteGallery(id)
            .then(data => {
                setStatusDelete('Success')
            })
            .catch(() => {
                setStatusDelete('Error')
            })
    }

    return (
        <>
            {galleris ?
                <div>
                    <button type="button" className="create-button" onClick={onCreateClick}>Create new</button>
                    <ul className='gallery-list'>
                        {galleris.map(gallery => (
                            <li key={gallery._id} className="gallary-box-edit">
                                <h3 className='list-title'>{gallery.nameGallery ? gallery.nameGallery : 'undefined'}</h3>
                                <div className='box-buttons'>
                                    <button type="button" className='edit-button pointer'
                                            onClick={() => onEditClick(gallery.nameGallery)}><LiaPencilAltSolid/> Edit
                                    </button>
                                    <button type="button" className='more-button pointer'>
                                        <PiDotsThreeOutlineVerticalFill/> More
                                    </button>
                                    <button type="button" className='more-button pointer' onClick={() => {
                                        deleteGalleryById(gallery._id)
                                    }}>
                                        <PiDotsThreeOutlineVerticalFill/> Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                : null

            }
        </>
    )
        ;
};

export default GalleryList;