import React, { useEffect, useState } from "react";
import './GalleryCardList.scss';
import noImage from '../../Assets/images/no-image.jpg'
import { Livepeer } from "livepeer";
import * as Player from "@livepeer/react/player";
import { getSrc } from "@livepeer/react/external";
import axios from "axios";

const livepeer = new Livepeer({
    apiKey: "524787ae-6a69-4994-bfcd-d0b737c6517b",
});

const getPlaybackSource = async (playbackId) => {
    const playbackInfo = await livepeer.playback.get(playbackId);
    const src = getSrc(playbackInfo.playbackInfo);
    return src;
};

const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getVideoSource = (sources) => {
    const isSafariBrowser = isSafari();

    if (isSafariBrowser) {
        const hlsSource = sources.find((source) => source.type === "hls");
        return hlsSource ? hlsSource.src : null;
    } else {
        const mp4Sources = sources.filter(
            (source) => source.type === "video" && source.mime === "video/mp4"
        );
        return mp4Sources.length > 0 ? mp4Sources[0].src : null;
    }
};

const GalleryCardList = ({ items, onItemDoubleClick }) => {
    const handleDoubleClick = (obj) => {
        onItemDoubleClick(obj);
    };

    const [collections, setCollections] = useState([]);
    const [playbackSources, setPlaybackSources] = useState({});

    const isVideo = (url) => {
        const regex = /https:\/\/lvpr\.tv\/\?v=([\w\d]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const fetchCollections = async (page = 1, accumulatedCollections = []) => {
        try {
            const response = await axios.get(`https://api.tribeton.io/api/collections?limit=10&sort=latest&page=${page}`);
            const newCollections = response.data.data;
            const allCollections = [...accumulatedCollections, ...newCollections];

            if (newCollections.length === 10) {
                // Continue fetching the next page
                fetchCollections(page + 1, allCollections);
            } else {
                // All collections have been fetched
                setCollections(allCollections);
            }
        } catch (error) {
            console.error("There was an error fetching the collections!", error);
        }
    };

    useEffect(() => {
        setCollections([]);
        fetchCollections();
    }, []);

    const isAudioFile = (article) => {
        const relatedCollection = collections.find(
            (col) => col._id === article?.collection_id
        );
        return (
            relatedCollection &&
            relatedCollection.category_id === "667ee42be03e65cd12d5b8c7"
        );
    };

    const getCollectionAvatar = (article) => {
        const relatedCollection = collections.find(
            (col) => col._id === article?.collection_id
        );
        return relatedCollection ? relatedCollection.avatar?.fileUrl : null;
    };

    const fetchPlaybackSources = async () => {
        const sources = {};
        for (const article of items) {
            const imageUrl =
                article.image?.previewUrl || article?.image?.fileUrl || noImage;
            const playbackId = isVideo(imageUrl);
            if (playbackId) {
                try {
                    const playbackSources = await getPlaybackSource(playbackId);
                    sources[article._id] = getVideoSource(playbackSources);
                } catch (error) {
                    console.error(
                        `Failed to fetch playback source for NFT ID ${article._id}`,
                        error
                    );
                }
            }
        }
        setPlaybackSources(sources);
    };

    useEffect(() => {
        fetchPlaybackSources();
    }, [items]);
console.log(items.collection_id);

    return (
        <ul className="gallery-cards" style={{ maxHeight: '580px', overflow: 'scroll' }}>
            {items.map(item => {
                const playbackId = isVideo(item.image?.previewUrl || item.image?.fileUrl || noImage);
                const collectionAvatar = getCollectionAvatar(item);
                console.log(item.collection_id)
                return (
                    <li
                        key={item._id}
                        className="gallery-item"
                        onDoubleClick={() => handleDoubleClick({
                            'title': item.name,
                            'collectionId':item.collection_id,
                            'owner': item.user,
                            'elementURL': item.image.fileUrl || item.image.previewUrl,
                            'urlSite': '/',
                            'NameGallery': item.name,
                            'id': item._id,
                            'categoryId': item.category_id,
                            

                        })}
                    >
                        {playbackId && playbackSources[item._id] ? (
                            <Player.Root
                                src={playbackSources[item._id]}
                                style={{ height: "100%" }}
                            >
                                <Player.Container >
                                    <Player.Video
                                        priority
                                        onError={(e) => {
                                            e.target.src = noImage;
                                        }}
                                        className="creator-video"
                                        style={{width: "90px",height:"110px",
                                            objectFit: "cover",cursor: "pointer"}}
                                    />
                                </Player.Container>
                            </Player.Root>
                        ) : isAudioFile(item) ? (
                            <div className="card_audio">
                                <img
                                    className="card__image-img"
                                    id="googleAnalyticsTriggerCardMarketplace"
                                    src={collectionAvatar}
                                    alt="audio"
                                />
                            </div>
                        ) : (
                            <img
                                src={item.image.fileUrl || item.image.previewUrl}
                                alt={item.title}
                                onError={(e) => {
                                    e.target.src = noImage;
                                }}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default GalleryCardList;
