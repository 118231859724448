import React, {useEffect, useState} from 'react';
import './seo.scss'
import {getAllSeoSetings, updateSeo} from "../../services/Seo";
import Sidebar from  '../../Components/Sidebar/Sidebar'
import Navbar from  '../../Components/Navbar/Navbar'

const Seo = () => {
    const [inputData,setInputData] = useState({keywords: '', description: '',socialTitle: '',})
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        getAllSeoSetings().then(data => console.log(data))
    }, []);

    const handleChangeImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);
            setFileUrl(URL.createObjectURL(selectedFile));
        }
    };

    const handleData = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
        console.log(inputData);
    };


    const changeSeo = async () => {
        setStatus('loading')
        const formData = new FormData();

        // Transform the keywords string into an array of strings
        const transformedKeywords = inputData.keywords.split(' ');  // Split the string by spaces

        const seoData = {
            keywords: transformedKeywords,
            description: inputData.description,
            socialTitle: inputData.socialTitle
        };

        formData.append('keywords', JSON.stringify(seoData.keywords));
        formData.append('description', seoData.description);
        formData.append('socialTitle', seoData.socialTitle);

        if (file) {
            formData.append('photo', file);
        }

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        try {
            const data = await updateSeo(formData, headers);
            setStatus('Success')
            // window.location.reload();
        } catch (error) {
            console.error(error);
            setStatus('Error')
        }
    };

    return (
        <div className='seoSideBar'>
            <Sidebar/>
            <div className="seoContainer">
                <Navbar/>
                <div className='seo'>
                    <h1>Seo Managment</h1>
                    <div className="form-input__name-blog">
                        <label>Keywords(Ключові слова)</label>
                        <input value={inputData.keywords} name="keywords" type="text" placeholder='Вводити кожне слово через пробіл ' onChange={handleData} />
                    </div>
                    <div className="form-input__name-blog">
                        <label>Description</label>
                        <input value={inputData.description}  name="description" type="text" placeholder='Опис ' onChange={handleData} />
                    </div>
                    <div className="form-input__name-blog">
                        <label>Title</label>
                        <input value={inputData.socialTitle}  name="socialTitle" type="text" placeholder='Назва сайту ' onChange={handleData} />
                    </div>

                    <div className='image-block-blog'>
                        {fileUrl && <img src={fileUrl} alt="Uploaded"/>}
                        <label className='label__image'>
                            <span>Upload Image </span>
                            <input
                                name='photo'
                                type="file"
                                className='modal-window__input-file'
                                accept='.jpeg,.jpg,.png'
                                onChange={handleChangeImage}
                            />
                        </label>
                        <span>Supported files: <span>jpeg, jpg, png</span>. Image will be resized into 250×170 px</span>
                    </div>

                    <button className="modal-form-btn-blog" type="submit" onClick={changeSeo}>Submit</button>

                   <h4 style={{fontSize: '20px', fontWeight: 500}}>{status}</h4>
                </div>
            </div>

        </div>
    );
};

export default Seo;