import axios from 'axios';

// const BASE_URL = 'http://localhost:3001';
export const BASE_URL = 'https://api.tribeton.io';

export const getCollectionItems = async (page) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/collection_items?page=${page}&limit=${15}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching collection items:', error);
        return [];
    }
};

export const getNFTItems = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/nft-items`);
        return response.data;

    } catch (error) {
        console.error('Error fetching NFT items:', error);
        return [];
    }
};

export const createGallery = async (name) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/gallery`, {"nameGallery": name});
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const createGalleryEllement = async (element) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/galleryelement`, element);
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const deleteGallery = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/gallery/delete/${id}`);
        return response;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
