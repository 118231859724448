import axios from "axios";
import {BASE_URL} from "./baseURL";

export const getBlogById = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/blog/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const updateBlogById = async (id,data) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/update/blog/${id}`,data);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

