import React, { useEffect, useState } from 'react';
import "./modalWindowBlog.scss";
import { formatDate } from "../../utils/utils";
import { createBlogPost } from "../../services/serverRequest";
import TXTEditor from "../TXTEdtior/Editor";
import {getBlogById, updateBlogById} from "../../services/Blog";

export const ModalWindowsCreateBlog = ({ active, setActive, update = false, articleId='',onBlogUpdate }) => {
    const [inputData, setInputData] = useState({ title: "", description: "", owner: "", additionaly: "", link: "" });
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [editorContent, setEditorContent] = useState('');
    const [data, setData] = useState('');


    useEffect(() => {
        if(update){
            getBlogById(articleId).then(data =>{
                if(data){
                    setInputData({ title: data.title, description: data.text, owner: data.owner, additionaly: data.additionally, link: data.link })
                    setFileUrl(data.image.fileUrl)
                    setData(data)
                }
            })
        }
        if (active) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [active,update,articleId]);

    const handleData = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const date = new Date();
        const formattedDate = formatDate(date);

        const formData = new FormData();
        formData.append('title', inputData.title);
        formData.append('text', editorContent);
        formData.append('owner', inputData.owner);
        formData.append('additionaly', inputData.additionaly);
        formData.append('link', inputData.link);
        formData.append('date', formattedDate);

        if (file) {
            formData.append('image', file);
        }

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        try {
            if (update && articleId) {
                await updateBlogById(data._id, formData);
                onBlogUpdate()
            } else {
                await createBlogPost(formData, headers);
            }
            // window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };


    const handleChangeImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);
            setFileUrl(URL.createObjectURL(selectedFile));
        }
    };

    const handleContentChange = (content) => {
        setEditorContent(content);
    };

    return (
        <div className={active ? "modal-window active modal-window-blog" : "modal-window modal-window-blog"} onClick={() => setActive(false)}>
            <div className={active ? "wrapper-form active" : "wrapper-form"} onClick={(e) => e.stopPropagation()}>
                <form onSubmit={handleSubmit} className='modal-window__form'>
                    <h3>Add Blog</h3>
                    <div className='form-block'>
                        <div className='form-input__image-blog'>
                            <span>Image</span>
                            <div className='image-block-blog'>
                                {fileUrl && <img src={fileUrl} alt="Uploaded" />}
                                <label className='label__image'>
                                    <span>Upload Image </span>
                                    <input
                                        name='photo'
                                        type="file"
                                        className='modal-window__input-file'
                                        accept='.jpeg,.jpg,.png'
                                        onChange={handleChangeImage}
                                    />
                                </label>
                                {file && <ul className="file-list">
                                    <li>{file.name}</li>
                                </ul>}
                                <span>Supported files: <span>jpeg, jpg, png</span>. Image will be resized into 250×170 px</span>
                            </div>
                            <div className='form-input__name-blog'>
                                <label>Additionaly</label>
                                <input value={inputData.additionaly} name="additionaly" onChange={handleData} />
                            </div>
                            <div className='form-input__name-blog'>
                                <label>Link related to the blog</label>
                                <input value={inputData.link} name="link" onChange={handleData} />
                            </div>
                        </div>
                        <div className='form-input__text-blog'>
                            <div className='form-input__name-blog'>
                                <label>Name</label>
                                <input value={inputData.title} name="title" type="text" onChange={handleData} />
                            </div>
                            <div className='form-input__name-blog'>
                                <label>Owner</label>
                                <input value={inputData.owner} name="owner" type="text" onChange={handleData} />
                            </div>
                            <TXTEditor
                                height={'285px'}
                                width={'100%'}
                                placeholder='Опис для блогу'
                                onContentChange={handleContentChange}
                                type='textarea'
                                resize={true}
                                initialValue={inputData.description}
                            />
                        </div>
                    </div>
                    <button className='modal-form-btn-blog' type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};
