import { useState } from "react";
import "./sidebar.scss";
import Logo from "../../Assets/images/logo.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CookieOutlinedIcon from "@mui/icons-material/CookieOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FaRegLifeRing } from "react-icons/fa";
import { TbBrandCss3 } from "react-icons/tb";
import { AiOutlineHtml5 } from "react-icons/ai";
import { FiInbox } from "react-icons/fi";
import { LiaNetworkWiredSolid } from "react-icons/lia";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="SideBar">
      <div className="top">
        <img className="logo" src={Logo} alt="logo" />
      </div>
      <div className="center">
        <ul className="menu-list">
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <HomeOutlinedIcon />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to="/moderation" style={{ textDecoration: "none" }}>
            <li>
              <HomeOutlinedIcon />
              <span>Moderation NFT</span>
            </li>
          </Link>
          <Link to="/category" style={{ textDecoration: "none" }}>
            <li>
              <MenuRoundedIcon />
              <span>Category</span>
            </li>
          </Link>
          <li className="dropdown">
            <div className="menu-item" onClick={handleDropdownClick}>
              <StoreMallDirectoryOutlinedIcon />
              <span>Manage NFT</span>
            </div>
          </li>
          <div className={`dropdown-content ${dropdownOpen ? "show" : ""}`}>
            <Link to="/manage-nft/active-items">Active NFT</Link>
            <Link to="/manage-nft/wait-to-publish">Wait to publish</Link>
            <Link to="/manage-nft/all-nft">All NFT</Link>
          </div>
          <Link to="/manage-item" style={{ textDecoration: "none" }}>
            <li>
              <ListOutlinedIcon />
              <span>Manage Item</span>
            </li>
          </Link>
          <Link to="/gallery" style={{ textDecoration: "none" }}>
            <li>
              <CollectionsIcon />
              <span>Gallery</span>
            </li>
          </Link>
          <Link to="/blog" style={{ textDecoration: "none" }}>
            <li>
              <CollectionsIcon />
              <span>Blog</span>
            </li>
          </Link>
          <Link to="/chagePriceLike" style={{ textDecoration: "none" }}>
            <li>
              <AttachMoneyIcon />
              <span>Change price for like</span>
            </li>
          </Link>
          <Link to="/nft-statistic" style={{ textDecoration: "none" }}>
            <li>
              <LeaderboardOutlinedIcon />
              <span>NFT Statistic</span>
            </li>
          </Link>
          <Link to="/manage-nft/active-items" style={{ textDecoration: "none" }}>
            <li>
              <LeaderboardOutlinedIcon />
              <span>Active Items</span>
            </li>
          </Link>
          <Link to="/manage-users" style={{ textDecoration: "none" }}>
            <li>
              <GroupOutlinedIcon />
              <span>Manage Users</span>
            </li>
          </Link>
          <Link to="/deposits" style={{ textDecoration: "none" }}>
            <li>
              <RequestQuoteOutlinedIcon />
              <span>Deposits</span>
            </li>
          </Link>
          <Link to="/withdrawals" style={{ textDecoration: "none" }}>
            <li>
              <AccountBalanceOutlinedIcon />
              <span>Withdrawals</span>
            </li>
          </Link>
          <Link to="/report" style={{ textDecoration: "none" }}>
            <li>
              <TocOutlinedIcon />
              <span>Report</span>
            </li>
          </Link>
          <Link to="/subscribers" style={{ textDecoration: "none" }}>
            <li>
              <ThumbUpOutlinedIcon />
              <span>Subscribers</span>
            </li>
          </Link>

          <span className="sidebar__point">Settings</span>
          <Link to="/contract-setting" style={{ textDecoration: "none" }}>
            <li>
              <LiaNetworkWiredSolid />
              <span>Contract Setting</span>
            </li>
          </Link>
          <Link to="/nft-storage-api" style={{ textDecoration: "none" }}>
            <li>
              <FiInbox />
              <span>NFT Storage Api</span>
            </li>
          </Link>
          <Link to="/general-setting" style={{ textDecoration: "none" }}>
            <li>
              <FaRegLifeRing />
              <span>General Setting</span>
            </li>
          </Link>
          <Link to="/system-configuration" style={{ textDecoration: "none" }}>
            <li>
              <SettingsOutlinedIcon />
              <span>System Configuration</span>
            </li>
          </Link>
          <Link to="/logo-favicon" style={{ textDecoration: "none" }}>
            <li>
              <PermMediaOutlinedIcon />
              <span>Logo & Favicon</span>
            </li>
          </Link>
          <Link to="/extensions" style={{ textDecoration: "none" }}>
            <li>
              <WorkspacesOutlinedIcon />
              <span>Extensions</span>
            </li>
          </Link>
          <Link to="/language" style={{ textDecoration: "none" }}>
            <li>
              <GTranslateOutlinedIcon />
              <span>Language</span>
            </li>
          </Link>
          <Link to="/seo" style={{ textDecoration: "none" }}>
            <li>
              <PublicOutlinedIcon />
              <span>SEO Manager</span>
            </li>
          </Link>
          <Link to="/kyc-setting" style={{ textDecoration: "none" }}>
            <li>
              <HowToRegOutlinedIcon />
              <span>KYC Setting</span>
            </li>
          </Link>
          <Link to="/notification-setting" style={{ textDecoration: "none" }}>
            <li>
              <NotificationsOutlinedIcon />
              <span>Notification Setting</span>
            </li>
          </Link>
          <span className="sidebar__point">Frontend Manager</span>
          <Link to="/manage-templates" style={{ textDecoration: "none" }}>
            <li>
              <AiOutlineHtml5 />
              <span>Manage Templates</span>
            </li>
          </Link>
          <Link to="/manage-pages" style={{ textDecoration: "none" }}>
            <li>
              <TocOutlinedIcon />
              <span>Manage Pages</span>
            </li>
          </Link>
          <Link to="/manage-section" style={{ textDecoration: "none" }}>
            <li>
              <ExtensionOutlinedIcon />
              <span>Manage Section</span>
            </li>
          </Link>
          <span className="sidebar__point">Extra</span>
          <Link to="/maintenance-mode" style={{ textDecoration: "none" }}>
            <li>
              <SmartToyOutlinedIcon />
              <span>Maintenance Mode</span>
            </li>
          </Link>
          <Link to="/gdpr-cookie" style={{ textDecoration: "none" }}>
            <li>
              <CookieOutlinedIcon />
              <span>GDPR Cookie</span>
            </li>
          </Link>
          <Link to="/system" style={{ textDecoration: "none" }}>
            <li>
              <StorageOutlinedIcon />
              <span>System</span>
            </li>
          </Link>
          <Link to="/custom-css" style={{ textDecoration: "none" }}>
            <li>
              <TbBrandCss3 />
              <span>Custom CSS</span>
            </li>
          </Link>
          <Link to="/report-request" style={{ textDecoration: "none" }}>
            <li>
              <BugReportOutlinedIcon />
              <span>Report & Request</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom"></div>
    </div>
  );
};

export default Sidebar;
