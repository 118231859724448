import React, {useEffect, useRef, useState} from 'react';
import './gallerycardnftlist.scss';
import imageGaleryDefault from '../../Assets/images/gallery_card.png'
import {LiaPencilAltSolid} from "react-icons/lia";
import {createGallery, createGalleryEllement} from "../../services/galleryService";

const GalleryCardNFTList = React.memo(({nft, editGalleryName}) => {

    const [nftList, setNftList] = useState([]);
    const [galleryName, setGalleryName] = useState('Name gallery')
    const [galleryStatus, setGalleryStatus] = useState('')
    const [galleryElement, setGalleryElement] = useState('')
    const inputRef = useRef(null);

    useEffect(() => {
        if (nft && !nftList.some(existingNft => existingNft.id === nft.id)) {
            setNftList(prevList => [...prevList, nft]);
            createElementGallery()
        }
    }, [nft]);

    useEffect(() => {
        const uniqueNftList = nftList.reduce((acc, currentNft) => {
            if (!acc.some(nft => nft.id === currentNft.id)) {
                acc.push(currentNft);
            }
            return acc;
        }, []);

        if (uniqueNftList.length !== nftList.length) {
            setNftList(uniqueNftList);
        }
    }, [nftList]);
    const handleInputChange = (e) => {
        setGalleryName(e.target.value);
    };

    const handleClickIcon = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };
    const sendDataToServer = () => {
        setGalleryStatus('loading gallery')

        createGallery(galleryName)
            .then(data => {
                setGalleryStatus(`${galleryName} successfully created`)
            })
            .catch(error => {
                setGalleryStatus(`${galleryName}: Error:(`)
            });

    };
    const createElementGallery = () => {
        const elementGallery = {
            "title": nft.title,
            "owner": nft.owner ? nft.owner : '/',
            "elementUrl": nft.elementURL,
            "urlSite": nft.urlSite,
            "galleryName": editGalleryName ? editGalleryName : galleryName,
            "marketId": nft.id,
            'collectionId':nft.collectionId,
            'categoryId': nft.categoryId
        }
        console.log(elementGallery);
        console.log(nft);
        
        
        setGalleryElement(`${nft.title}: 'loading'`)
        createGalleryEllement(elementGallery)
            .then(() => {
                setGalleryElement(`${nft.title}: 'success'`)
            })
            .catch(() => {
                setGalleryElement(`${nft.title}: 'Error'`)
            })

    }


    return (
        <>
            <div className='galleryBox'>
                {
                    editGalleryName ?
                        null :
                        <>
                            <input
                                ref={inputRef}
                                onChange={handleInputChange}
                                value={galleryName}
                                className="gallery-title-h3"
                            />
                            <LiaPencilAltSolid
                                style={{cursor: 'pointer'}}
                                onClick={handleClickIcon}
                            />
                        </>

                }

                {editGalleryName ?
                    <></>
                    :
                    <button className='galleryButtonCreate' onClick={sendDataToServer}>Create Gallery</button>
                }

            </div>
            <div className="gallery-cards">
                {nftList.map((nft) => (
                    <img key={nft._id} src={nft.elementURL ? nft.elementURL : imageGaleryDefault} alt={nft.id}/>
                ))}
            </div>
            {editGalleryName
                ? "You can add new elements to this gallery" :
                'Press create gallery and after add elements'
            }
            <h5 style={{fontSize: '17px', fontWeight: 500}}><br/>{galleryStatus}</h5>
            <h5 style={{fontSize: '17px', fontWeight: 500}}><br/>{galleryElement}</h5>
        </>

    );
});

export default GalleryCardNFTList;
