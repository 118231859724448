import React, { useState, useEffect } from 'react';
import './modalimage.scss'; // Убедитесь, что у вас есть этот файл стилей
import { Livepeer } from "livepeer";
import * as Player from "@livepeer/react/player";
import { getSrc } from "@livepeer/react/external";
import axios from "axios";
import { BASE_URL } from '../../services/baseURL';
const livepeer = new Livepeer({
    apiKey: "524787ae-6a69-4994-bfcd-d0b737c6517b",
});

const getPlaybackSource = async (playbackId) => {
    try {
        const playbackInfo = await livepeer.playback.get(playbackId);
        return getSrc(playbackInfo.playbackInfo);
    } catch (error) {
        console.error(`Error fetching playback source for ID ${playbackId}:`, error);
        throw error;
    }
};

const isVideo = (url) => {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get('v');
    return videoId ? videoId : null;
};

const isAudioFile = (article, collections) => {
    const relatedCollection = collections.find(
        (col) => col._id === article?.collection_id
    );
    return relatedCollection && relatedCollection.category_id === "667ee42be03e65cd12d5b8c7";
};

const getCollectionAvatar = (article, collections) => {
    const relatedCollection = collections.find(
        (col) => col._id === article?.collection_id
    );
    return relatedCollection ? relatedCollection.avatar?.fileUrl : null;
};

const ModalImage = ({ imageSrc, altText, nft, imgClass }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [videoSrc, setVideoSrc] = useState('');
    const [collections, setCollections] = useState([]);
    
    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);
    const handleImageLoad = () => setIsLoading(false);

    const fetchCollections = async () => {
        try {
            let allCollections = [];
            let currentPage = 1;
            let moreItems = true;
    
            while (moreItems) {
                const response = await axios.get(`https://api.tribeton.io/api/collections?limit=10&sort=latest&page=${currentPage}`);
                const fetchedCollections = response.data.data;
    
                if (fetchedCollections.length > 0) {
                    allCollections = [...allCollections, ...fetchedCollections];
                    currentPage += 1; // Increment the page number to fetch the next set of items
                } else {
                    moreItems = false; // Stop fetching if no more items are returned
                }
            }
    
            setCollections(allCollections); // Set the state with the combined results
        } catch (error) {
            console.error("There was an error fetching the collections!", error);
        }
    };

    useEffect(() => {
        fetchCollections();
    }, []);
    
    const loadVideoSrc = async () => {
        const playbackId = isVideo(imageSrc);
        if (playbackId) {
            try {
                // For simplicity, directly using the provided URL as video source
                setVideoSrc(imageSrc);
            } catch (error) {
                console.error("Error loading video source", error);
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        loadVideoSrc();
    }, [imageSrc]);

    const collectionAvatar = getCollectionAvatar(nft, collections);

    return (
        <div>
            {isLoading && <p>Loading...</p>}
            {videoSrc ? (
                <div className="video-container">
                    <iframe src={videoSrc} frameborder="0"></iframe>
                </div>
            ) : isAudioFile(nft, collections) ? (
                <div className="card_audio" style={{ position: "relative" }}>
                    <img src={collectionAvatar} alt="audio" style={{ position: "relative" }} />
                    <audio controls style={{ position: "absolute", left: "0", bottom: '0', width:"100%"}}>
                        <source src={imageSrc}/>
                        Your browser does not support the audio element.
                    </audio>
                </div>
            ) : (
                <img
                    src={imageSrc}
                    alt={altText}
                    onClick={handleOpenModal}
                    onLoad={handleImageLoad}
                    className={imgClass}
                    style={{ cursor: 'pointer', width: '200px', display: isLoading ? 'none' : 'block' }}
                />
            )}

            {isOpen && (
                <div className="modal" onClick={handleCloseModal}>
                    <span className="close">&times;</span>
                    <img className='modal-content' src={imageSrc} alt={altText} />
                </div>
            )}
        </div>
    );
};

export default ModalImage;
