import {useEffect, useState} from 'react';
import BackButton from "../BackButton/BackButton";

import GalleryList from "../../Components/GalleryList/GalleryList";
import './GalleryDetails.scss';
import GalleryCardNFTList from "../GalleryCardList/GalleryCardNFTList";

const GalleryDetails = ({nft}) => {

    const [viewMode, setViewMode] = useState('list');
    const [editGalleryName, setEditGalleryName] = useState('');
    const [createElements, setCreateElements] = useState('');
    const [editElements, setEditElements] = useState('');

    const handleListClick = () => {
        setEditGalleryName('')
        setViewMode('list');
    };

    const handleCreateClick = () => {
        setViewMode('cards');
    };

    const handleEditClick = (name) => {
        setEditGalleryName(name)
        setViewMode('cards');
    };

    useEffect(() => {
        if (editGalleryName) {
            setEditElements(nft)
        } else {
            setCreateElements(nft)
        }
    }, [nft]);

    return (
        <div className="gallery-details">
            {viewMode === 'cards' && (
                <>
                    <BackButton text="List" onClick={handleListClick}/>
                    <h2 className="gallery-title">Gallery</h2>
                    {editGalleryName
                        ? <GalleryCardNFTList nft={editElements} editGalleryName={editGalleryName}/>
                        :
                        <GalleryCardNFTList nft={createElements} editGalleryName={''}/>
                    }

                </>
            )}
            {viewMode === 'list' && (
                <>
                    <GalleryList onCreateClick={handleCreateClick} onEditClick={handleEditClick}/>
                </>
            )}
        </div>
    );
};

export default GalleryDetails;