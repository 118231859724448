import {useState, useEffect, useCallback} from "react";
import SearchHere from "../Search/Search";
import BackButton from "../BackButton/BackButton";
import FilterGalleryBar from "../../Components/FilterGalleryBar/FilterGalleryBar";
import GalleryCardList from "../../Components/GalleryCardList/GalleryCardList";
import { getCollectionItems, getNFTItems } from '../../services/galleryService';
import './GalleryMain.scss';

const GalleryMain = ({ onItemDoubleClick }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [initialItems, setInitialItems] = useState([]);

    const pageSize = 15;


    const fetchAllCollectionItems = useCallback(async (page = 1, accumulatedItems = []) => {
        const response = await getCollectionItems(page, pageSize);
        const newItems = response.data || [];
        const totalCount = response.totalCount;

        const allItems = [...accumulatedItems, ...newItems];

        if (page * pageSize < totalCount) {
            return fetchAllCollectionItems(page + 1, allItems);
        }
        return allItems;
    }, [pageSize]);

    useEffect(() => {
        Promise.all([fetchAllCollectionItems(), getNFTItems()])
            .then(responses => {
                const combinedItems = [
                    ...responses[0],
                    ...(responses[1]?.data || [])
                ];
                setInitialItems(combinedItems);
            })
            .catch(error => console.error('Error fetching gallery data:', error));
    }, []);

    const handleSearchResults = (results) => {
        setSearchResults(results);
    };

    const handleItemDoubleClick = (obj) => {
        onItemDoubleClick(obj);
    };

    return (
        <>
            {initialItems ?
                <div className="gallery-main">
                    <div className="gallary-nav">
                        <div>
                            <h2 className="gallary-title">All NFT</h2>
                            <BackButton text="Filter" onClick={() => console.log('Back button clicked')}/>
                        </div>
                        <SearchHere
                            onSearch={handleSearchResults}
                            apiPath="/api/gallery"
                        />
                    </div>
                    <div className="gallery-filtrs-cards">
                        <FilterGalleryBar/>
                        <GalleryCardList items={searchResults.length > 0 ? searchResults : initialItems}
                                         onItemDoubleClick={handleItemDoubleClick}/>
                    </div>
                </div>
                : null
            }
        </>
    );
};

export default GalleryMain;
