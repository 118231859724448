import axios from "axios";
import {BASE_URL} from "./baseURL";

export const createNewPriceEntry = async (data,headers) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/admin/like-price`, data);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const updateExistingLikePriceEntry = async (data) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/admin/like-price`, data);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const getAllLikePrice = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/admin/like-prices`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const getLikePriceById = async (priceId) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/admin/like-prices/${priceId}`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}
