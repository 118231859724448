import axios from "axios";
import {BASE_URL} from "./baseURL";

export const updateSeo = async (data,headers) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/manage/seo/66a212f37f4b3ea3a45f8436`,data,headers);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}

export const getAllSeoSetings = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/manage/allseo`);
        return response.data;
    } catch (error) {
        console.error('Error creating gallery:', error.message);
    }
}